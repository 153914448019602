const LinkComponent = (props) => {
  const formattedHref = props.href?.includes('http')
    ? props.href
    : `//${props.href}`;

  return (
    <>
      <a href={formattedHref} target='_blank' rel='noreferrer'>
        {props.children}
      </a>
    </>
  );
};

export default LinkComponent;
