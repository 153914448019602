import 'styles/BotAvatar.css';
import PropTypes from 'prop-types';
import {
  Mood,
  SentimentVeryDissatisfied as SadFace,
} from '@mui/icons-material';

const BotAvatar = ({ isWorking }) => {
  return (
    <div className='bot-avatar-container'>
      {isWorking ? <Mood /> : <SadFace />}
    </div>
  );
};

BotAvatar.propTypes = {
  isWorking: PropTypes.bool,
};

export default BotAvatar;
