import { useState, createContext, useEffect } from 'react';
import { axiosApi as axios } from 'utilidades';
import Chatbot from 'react-chatbot-kit';
import ActionProvider from './configs/ActionProvider';
import MessageParser from './configs/MessageParser';
import './styles/Header.css';
import 'react-tooltip/dist/react-tooltip.css';
import getConfig, { backupConfig } from './configs/config';
import { FaRobot } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ErrorBoundary from './ErrorBoundary';

export const context = createContext();

const App = () => {
  const { Provider } = context;
  const [showAsistente, setShowAsistente] = useState(false);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    getInitialMenu();
  }, []);

  async function getInitialMenu() {
    try {
      const response = await axios.get('/api/menu?menu=0');
      setMenu(response.data.questions);
    } catch (error) {
      setMenu(null);
    }
  }

  return (
    <Provider
      value={{
        setShowAsistente,
        showAsistente,
      }}
    >
      {showAsistente ? (
        <div>
          <ErrorBoundary
            fallback={
              <Chatbot
                config={backupConfig}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                placeholderText='Escribe tu mensaje aquí...'
              />
            }
          >
            <Chatbot
              config={getConfig(menu)}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              placeholderText='Escribe tu mensaje aquí...'
            />
          </ErrorBoundary>
        </div>
      ) : (
        <>
          <button
            onClick={() => setShowAsistente((prev) => !prev)}
            className='first-button-container'
            aria-label='Iniciar asistente'
          >
            <FaRobot size={24} />
          </button>
          <ReactTooltip anchorSelect='.first-button-container' place='top'>
            Iniciar asistente virtual
          </ReactTooltip>
        </>
      )}
    </Provider>
  );
};

export default App;
