import 'styles/Botones.css';
import { v4 as uuid } from 'uuid';

const Options = (props) => {
  const { payload, initialMenu } = props;

  return (
    <>
      <div className='button-container'>
        {payload ? (
          <>
            {payload.options.map((option) => {
              return (
                <button
                  key={uuid()}
                  onClick={() => {
                    props.actionProvider.clientMessage(option.title);
                    props.actionProvider.handleResponse(option);
                  }}
                  className='button'
                >
                  {option.title}
                </button>
              );
            })}
            {props.state?.messages?.length > 2 && (
              <button
                onClick={props.actionProvider.handleWelcome}
                className='button'
              >
                Volver
              </button>
            )}
          </>
        ) : (
          initialMenu.map((option) => {
            return (
              <button
                key={uuid()}
                onClick={() => {
                  props.actionProvider.clientMessage(option.title);
                  props.actionProvider.handleResponse(option);
                }}
                className='button'
              >
                {option.title}
              </button>
            );
          })
        )}
      </div>
    </>
  );
};

export default Options;
