import { useContext } from 'react';
import { context } from 'App';
import { Close } from '@mui/icons-material';
import { FaRobot } from 'react-icons/fa';

const Header = ({ botName }) => {
  const { setShowAsistente } = useContext(context);

  return (
    <div className='header-container'>
      <FaRobot size='20px' style={{ color: '#fff' }} />
      <p className='header-title'>{botName}</p>
      <div className='header-close-button-container'>
        <button onClick={(prev) => setShowAsistente(!prev)}>
          <Close />
        </button>
      </div>
    </div>
  );
};

export default Header;
