import React from 'react';
import { axiosApi as axios } from 'utilidades';

function MessageParser({ children, actions }) {
  const parse = async (message) => {
    if (!message) return;

    const data = {
      frase: message,
    };

    try {
      const response = await axios.post(`/api/prediccion`, data);

      // Chequeo si data es INDEFINIDO
      // Si es asi, significa que no logro encontrar una respuesta en la base
      if (response.data === 'INDEFINIDO') {
        throw new Error();
      } else {
        return actions.handleClientMessageResponse(response.data);
      }
    } catch (error) {
      return actions.handleDefaultAnswer(message);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {
            ...actions,
          },
        });
      })}
    </div>
  );
}
export default MessageParser;
