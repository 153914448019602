import { createChatBotMessage } from 'react-chatbot-kit';
import Header from '../components/Header/Header';
import Botones from '../components/Botones/Botones';
import BackComponent from 'components/Back/BackComponent';
import BotAvatar from 'components/BotAvatar/BotAvatar';

const BOT_NAME = 'Asistente Virtual';

const getConfig = (initialMenu) => {
  const hayMenues = initialMenu?.length > 0;

  return {
    initialMessages: hayMenues
      ? [
          createChatBotMessage(`Hola!, ¿en qué te puedo ayudar?`),
          createChatBotMessage(
            'Hace click sobre el tema que quieras consultar',
            {
              widget: 'botones',
            }
          ),
        ]
      : [
          createChatBotMessage(
            'En estos momentos el asistente virtual se encuentra fuera de servicio.'
          ),
        ],
    lang: 'es',
    widgets: [
      {
        widgetName: 'botones',
        widgetFunc: (props) => <Botones {...props} initialMenu={initialMenu} />,
      },
      {
        widgetName: 'volver',
        widgetFunc: (props) => (
          <BackComponent {...props} isWorking={initialMenu != null} />
        ),
      },
    ],

    customComponents: {
      header: () => <Header botName={BOT_NAME} />,
      botAvatar: (props) => (
        <BotAvatar {...props} isWorking={initialMenu != null} />
      ),
    },
  };
};

export default getConfig;

export const backupConfig = {
  initialMessages: [
    createChatBotMessage(
      'En estos momentos el asistente virtual se encuentra fuera de servicio.'
    ),
  ],
  lang: 'es',
  customComponents: {
    header: () => <Header botName={BOT_NAME} />,
    botAvatar: (props) => <BotAvatar {...props} isWorking={false} />,
  },
};
