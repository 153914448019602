import React from 'react';
import { defaultActions } from 'actions/default';
import { createClientMessage } from 'react-chatbot-kit';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import LinkComponent from '../LinkComponent';
import { v4 as uuid } from 'uuid';
import { cacheResponse } from './cache';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  // Handle response
  const handleResponse = async (option) => {
    // const tieneSubMenu = option.tiene_submenues === 1;

    try {
      // Si apretan en "Colegio"
      // Con option, id_menu me traigo el menu para responder sobre "Colegio"
      // Con la data que me responde el back, muestro la respuesta, y si tiene submenues, los muestro tambien.
      const data = await cacheResponse(option);

      const tieneSubMenu = data.questions?.length > 0;
      const totalQuestions = tieneSubMenu ? data.questions.length : null;

      const message = createChatBotMessage(
        <ReactMarkdown components={{ a: LinkComponent }} key={uuid()}>
          {data.defaultMessage === '' && totalQuestions > 0
            ? 'Elige una de las siguientes opciones'
            : data.defaultMessage}
        </ReactMarkdown>,
        tieneSubMenu
          ? {
              widget: 'botones',
              payload: {
                options: data.questions,
              },
            }
          : {
              widget: 'volver',
            }
      );

      // Creo el mensaje
      setState((prev) => {
        return {
          ...prev,
          messages: [...prev.messages, message],
        };
      });
    } catch (error) {
      const message = createChatBotMessage('Ocurrió un error', {
        widget: 'volver',
      });

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
  };

  // Mensaje luego de prediccion
  // Voy a recibir la respuesta de la api
  function handleClientMessageResponse(data) {
    const tieneSubMenu = data.questions && data.questions.length > 0;

    const totalQuestions = tieneSubMenu ? data.questions.length : null;

    const botMessage = createChatBotMessage(
      <ReactMarkdown key={uuid()} components={{ a: LinkComponent }}>
        {data.defaultMessage === '' && totalQuestions > 0
          ? 'Elige una de las siguientes opciones'
          : data.defaultMessage}
      </ReactMarkdown>,
      tieneSubMenu
        ? {
            widget: 'botones',
            payload: {
              options: data.questions,
            },
          }
        : {
            widget: 'volver',
          }
    );

    setState((prev) => {
      return {
        ...prev,
        messages: [...prev.messages, botMessage],
      };
    });
  }

  // Mensajes del cliente
  const clientMessage = (text) => {
    const message = createClientMessage(text);

    setState((prev) => {
      return {
        ...prev,
        messages: [...prev.messages, message],
      };
    });
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            clientMessage,
            handleResponse,
            handleClientMessageResponse,
            ...defaultActions(createChatBotMessage, setState),
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
