import axios from 'axios';

export const getApiUrl = () =>
  window._env_ ? window._env_.REACT_APP_API_URL : process.env.REACT_APP_API_URL;

// Creo axios base url
export const axiosApi = axios.create({
  baseURL: getApiUrl(),
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});
