import { axiosApi as axios } from 'utilidades';

const options = [];

export async function cacheResponse(query) {
  // Chequear si ya lo tengo cacheado
  const cachedData = getOption(query);

  if (cachedData) {
    return cachedData;
  } else {
    const response = await axios.get(`/api/menu?menu=${query.id_menu}`);

    const { data } = response;

    options.push(data);

    return data;
  }
}

function getOption(query) {
  return options.find(
    (each) => each.id_menu === query.id_menu || each.title === query.title
  );
}

// Ejemplo

/* 
 {
    "TOTAL": 0,
    "defaultMessage": "Si sos alumno o ya fuiste alumno, y quer\u00e9s inscribirte a una nueva carrera deb\u00e9s realizar la inscripci\u00f3n a la propuesta por [Autogesti\u00f3n](https://autogestion.guarani.unlp.edu.ar/)",
    "questions": [],
    "title": "Ya soy estudiante"
}
 */
