export function defaultActions(createChatBotMessage, setState) {
  // Mensaje de bienvenida
  const handleWelcome = async () => {
    const botMessage = createChatBotMessage(
      'Hace click sobre el tema que quieras consultar',
      {
        widget: 'botones',
      }
    );

    setState((prev) => {
      return {
        ...prev,
        messages: [prev.messages[0], botMessage],
      };
    });
  };

  // Cuando no entiendo la pregunta, lo mando aca
  const handleDefaultAnswer = () => {
    const botMessage1 = createChatBotMessage('No logro entender tu pregunta', {
      widget: 'volver',
    });

    setState((prev) => {
      return {
        ...prev,
        messages: [...prev.messages, botMessage1],
      };
    });
  };

  return {
    handleWelcome,
    handleDefaultAnswer,
  };
}
