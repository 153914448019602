import 'styles/BackComponent.css';

const BackComponent = (props) => {
  return (
    <div className='button-container'>
      <button className='button' onClick={props.actionProvider.handleWelcome}>
        Volver
      </button>
    </div>
  );
};

export default BackComponent;
